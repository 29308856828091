@import "../../../utils/css/variables.css";
@import "../../../utils/css/mixins.css";

.c-icon-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;

  @mixin bp $tablet {
    width: 50px;
    height: 50px;
  }

  &--up {
    transform: rotate(-90deg);
  }

  &--right {
    
  }

  &--down {
    transform: rotate(90deg);
  }

  &--left {
    transform: rotate(-180deg);
  }

  &--up-right {
    transform: rotate(-45deg);
  }

  &--background {
    background-color: $white;
  }
  
  &--background &__group {
    stroke: $black;
  }

  &:not(&--up-right) &__mobile {
    @mixin bp $tablet {
      display: none;
    }
  }

  &--up-right &__tablet {
    display: none;
  }
  
  &__tablet {
    @mixin bpMax $tablet {
      display: none;
    }
  }

  g {
    fill: transparent;
  }
}