@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-login {
  &__headline {
    @mixin bp $tablet {
      width: calc((100% - $gutter * 2) / 3 * 2 + $gutter);
    }
  }

  &__login {
    position: relative;
    left: -$pageGutter;
    width: calc(100% + $pageGutter + $gutter);
    grid-column: 1 / span 12;
    padding-left: $pageGutter;
    padding-right: $gutter;
    
    @mixin bp $tablet {
      grid-column: 1 / span 3;
    }

  }

  &__page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  &__background {
    @mixin bpMax $tablet {
      display: none;
    }

    @mixin bp $tablet {
      width: calc(100% + $gutter);
      grid-column: 4 / span 9;
    }
  }

  &__background-image {
    object-fit: cover;
    height: 100%;

    &__container {
      position: relative;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }

    &__caption {
      position: absolute;
      bottom: $gutter;
      right: $pageGutter;
      font-size: 1.3125rem;
      line-height: 1;
      letter-spacing: -0.03571428571em;
    }

    &__caption-text {
      margin: 0;
    }
  }
}