.c-dev {
  /* &__toggle {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 2em;
    height: 2em;
    background-color: $grey;
  } */

  &__guides {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 111111111;
    display: none;
    justify-content: center;
    pointer-events: none;

    .s-active & {
      display: flex;
    }
  }
  
  &__columns-container {
    justify-content: space-between;
    padding-right: $pageGutter;
    padding-left: $pageGutter;
    outline: 1px solid red;

    /* @include bp($tablet) {
      padding-right: $pageGutter * 2;
      padding-left: $pageGutter * 2;
    }

    @include bp($laptopBig) {
      padding-right: $pageGutter * 4;
      padding-left: $pageGutter * 4;
    }

    @include bp($desktop) {
      padding-right: $pageGutter * 6;
      padding-left: $pageGutter * 6;
    } */
  }

  &__column {
    outline: 1px solid blue;
  }
}

.xdebug-var-dump {
  position: relative;
  z-index: 1;
  background-color: white;
  border: 1px solid red;
}