@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-app {
  
}

.c-header__inner,
.c-page,
.c-edit,
.c-partner,
.c-engine-project__title-container,
.c-engine-project__close {
  will-change: transform;
  transition: transform 300ms ease-in-out;
  
  .c-app--is-editing & {
    transform: translateX(-100%);

    @mixin bp $tablet {
      transform: translateX(calc(((100vw - $gutter * 13) / 12 * 4 + $gutter * 3) * -1 - $pageGutter * 2));
    }
  }
}

.c-page {
  /* margin-bottom: $pageGutter; */
}

.c-app--is-editing .c-main {
  position: relative;
  z-index: 1;
}
