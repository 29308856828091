@import "../../../utils/css/variables.css";
@import "../../../utils/css/mixins.css";

.c-engine-project {
  &__close {
    position: absolute;
    top: 12.275rem;
    right: $pageGutter;

    @mixin bp $tablet {
      top: 17.1125rem;
      right: auto;
    }
  }

  &__title-container {
    position: relative;
    z-index: 2;
    /* Using height to push down .c-pager without overriding styles */
    height: 7.6875rem;
    grid-column-end: span 12;

    @mixin bp $tablet {
      grid-column-start: 4;
      grid-column-end: span 3;
    }
  }

  &__title {
    position: relative;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    letter-spacing: -0.02857142857em;

    @mixin bpMax $tablet {
      position: absolute;
      bottom: -121px;
    }

    @mixin bp $tablet {
      top: 0.3rem;
      font-size: 1.3125rem;
      line-height: 1.2380952381;
    }

    span {
      display: block;
    }
  }

  &__edit {
    margin-top: .3rem;
  }

  &__page-header {
    border-bottom: 1px solid $uiDarkGrey;

    &__title {
      grid-column-end: span 12;
      margin-top: .75rem;
      margin-bottom: 6.5625rem;
      font-size: 1.3125rem;
      line-height: 1;
      letter-spacing: -0.02857142857em;
      
      @mixin bp $tablet {
        margin-top: 1.1875rem;
        margin-bottom: 9rem;
        font-size: 1.75rem;
        letter-spacing: -0.03214285714em;
      }
    }
  }

}