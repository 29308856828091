@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-project {
  &__title-container {
    position: relative;
    height: 123px;

    @mixin bp $tablet {
      grid-column-start: 4;
      grid-column-end: span 3;
    }
  }

  &__title {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    letter-spacing: -0.02857142857em;

    @mixin bpMax $tablet {
      position: absolute;
      bottom: -121px;
    }

    @mixin bp $tablet {
      font-size: 1.3125rem;
      line-height: 1.2380952381;
    }
  }
}