body {
  margin: 0;
  background-color: $bgColor;
}

.c-page {
  min-height: calc(100vh - 123px);
  min-height: calc(var(--vh, 1vh) * 100 - 123px);
  margin-top: 123px;
  border-top: 1px solid $uiDarkGrey;

  @mixin bp $tablet {
    min-height: calc(100vh - 220px);
    min-height: calc(var(--vh, 1vh) * 100 - 220px);
    margin-top: 220px;
  }
}