@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-toggle {
  display: flex;
  align-items: center;
  background-color: $dimmedToggle;
  width: 2.8125rem;
  height: 1.5625rem;
  padding: 0.15625rem;
  border-radius: .78125rem;
  will-change: background-color;
  transition: background-color 200ms ease-in-out;

  &--toggled {
    background-color: $white;
  }

  &--normal {
    @mixin bp $tablet {
      width: 3.625rem;
      height: 2rem;
      padding: .25rem;
      border-radius: 1rem;
    }
  }

  &--small {
    @mixin bp $tablet {
      width: 2.25rem;
      height: 1.25rem;
      padding: 0.15625rem;
      border-radius: 0.625rem;
    }
  }

  &__toggle {
    width: 1.25rem;
    height: 1.25rem;
    background-color: $black;
    border-radius: 50%;
    will-change: transform;
    transition: transform 200ms ease-in-out;
  }

  &--toggled &__toggle {
    transform: translateX(1.25rem);
  }

  &--normal &__toggle {
    @mixin bp $tablet {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--normal&--toggled &__toggle {
    @mixin bp $tablet {
      transform: translateX(1.625rem);
    }
  }

  &--small &__toggle {
    @mixin bp $tablet {
      width: 0.9375rem;
      height: 0.9375rem;
    }
  }

  &--small&--toggled &__toggle {
    @mixin bp $tablet {
      transform: translateX(1rem);
    }
  }
}