@font-face {
  font-family: 'HelveticaNowText-Regular';
  src:  url('/fonts/regular.woff2') format('woff2'),
        url('/fonts/regular.woff') format('woff');
}


body {
  font-family: $ffBody;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  letter-spacing: -0.016875em;
  line-height: 1.25;
}

input {
  font-family: inherit;  
}

body,
a {
  color: $color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.f-small {
  font-size: .75rem;
  letter-spacing: -0.01642857143em;

  @mixin bp $tablet {
    font-size: .875rem;
  }
}

.f-dimmed {
  &,
  a {
    color: $dimmedText;
  }

  a:hover {
    color: $color;
  }
}

.f-error {
  color: $white;
}

.f-success {
  color: green;
}