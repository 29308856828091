@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-partners {
  position: relative;

  &__header {
    position: absolute;
    top: -3.1em;
    width: 100%;

    @mixin bp $tablet {
      top: -5.25em;
    }

    &__link-right {
      position: absolute;
      bottom: calc($gutter / 2);
      right: $pageGutter;
      display: block;

      @mixin bp $tablet {
        bottom: $gutter;
      }
    }
  }
  
  .c-search-form {
    width: calc(100% - $pageGutter * 2);
  }

  &__partners {
    margin-top: 4.3125rem;

    @mixin bp $tablet {
      margin-top: 5rem;
    }
  }
}