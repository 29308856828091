/* BREAKPOINTS */
$small: 0px;
$mobileMedium: 350px;
$mobileLarge: 400px;
$tablet: 764px;
$laptop: 1024px;
$laptopBig: 1280px;
$desktop: 1440px;
$huge: 1560px;
$giant: 1920px;

$iPhone5Height: 568px;


/* Z-INDEX */
$zHeader: 9000;
$zBelow: -1;
$zBottomlessPit: -9000;


/* SIZES */
$columns: 12;
$columnWidth: 140px;
$gutter: 20px;
$gutterVertical: 1.5rem;
$pageGutter: 20px;
$widthPage: 100%;
$widthPageInner: $columnWidth * $columns + $gutter * ( $columns - 1) + $pageGutter * 2;


/* COLORS */
$white: #fff;
$red: #DA0000;
$darkGrey: #404040;
$red: #EE0000;
$uiDarkGrey: #4D4D4D;
$editInputGrey: #767676;
$inputFocusBackground: #595959;
$lightGrey: #9E9E9E;
$black: #000000;
$bgTag: #262626;
$buttonTextDark: #262626;
$colorTag: #7C7C7C;
$dimmedText: #666666;
$dimmedToggle: #444444;
$bgColor: $black;
$color: $white;


/* FONTS */
$ffRegular: 'HelveticaNowText-Regular', 'Helvetica', sans-serif;

$ffBody: $ffRegular;

/* STYLES TO EXTEND */
%grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: $gutter;
  max-width: $widthPage;
  
  &:not([class*="l-column--"]) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

%grid--outmost {
  padding-right: $pageGutter;
  padding-left: $pageGutter;
}