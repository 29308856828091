@import "../../../../utils/css/variables.css";
@import "../../../../utils/css/mixins.css";

.c-onboarding-overview {

  &__list-item {
    display: grid;
    padding-top: .8125rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid $lightGrey;
    color: $lightGrey;

    @mixin bp $tablet {
      padding-top: .8125rem;
      padding-bottom: .6875rem;
    }

    &.s-active,
    &--link {
      padding-top: .55rem;
      padding-bottom: 4.7rem;
      text-decoration: none;
      color: $color;

      @mixin bp $tablet {
        padding-top: .7rem;
      }
    }
  }

  &__headline {
    grid-column-end: span 9;
    margin: 0;
    font-size: 1.3125rem;
    letter-spacing: -0.02857142857em;

    @mixin bp $tablet {
      font-size: 1.75rem;
      letter-spacing: -0.01678571429em;
    }
  }

  &__list-item.s-active &__headline,
  &__list-item--link &__headline {
    font-size: 2.1875rem;
    letter-spacing: -0.01514285714em;

    @mixin bp $tablet {
      font-size: 2.875rem;
      letter-spacing: -0.015em;
    }
  }

  &__right {
    grid-column-start: 10;
    grid-column-end: span 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__icon {
    svg {
      width: 100%;
      height: 100%;
    }

    &--checkmark {
      position: relative;
      top: -0.2rem;
      width: 2rem;
      height: 1.5625rem;

      @mixin bp $tablet {
        width: 2.0625rem;
        height: 2.75rem;
      }
    }

    &--arrow {
      width: 1.625rem;
      height: 1.75rem;

      @mixin bp $tablet {
        width: 2.9375rem;
        height: 2.5rem;
      }

      .c-icon-arrow {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__number {
    width: .6em;
    margin: .0625rem 0 0 1rem;
    font-size: 2.1875rem;
    line-height: 1;
    letter-spacing: -0.01514285714em;
    text-align: right;

    @mixin bp $tablet {
      margin-top: 0;
      font-size: 2.875rem;
      letter-spacing: -0.015em;
    }
  }

  &__text {
    grid-column-end: span 8;
    margin: .1rem 0 0 0;
    font-size: 1.3125rem;
    letter-spacing: -0.02857142857em;
    line-height: 1.0952380952;
    color: $lightGrey;

    @mixin bp $tablet {
      max-width: 15em;
      margin-top: 0.55rem;
      font-size: 1.75rem;
      letter-spacing: -0.03214285714em;
      line-height: 1.0714285714;
    }
  }
}