.react-autosuggest {
  &__container {
    position: relative;
    z-index: 1;

    .c-input__input {
      position: relative;
      z-index: 1;
    }
  }

  &__suggestions-container {
    position: absolute;
    width: 100%;
    background-color: $white;
    border-radius: 2px;
    overflow: hidden;

    &--open {

    }
  }

  &__suggestions-list {
    list-style-type: none;
  }

  &__suggestion {
    padding: .675em .65em;
    background-color: #5f5f5f;
    color: $darkGrey;
    color: $white;

    @mixin bp $tablet {
      padding-right: 0.55em;
      padding-left: 0.55em;
    }

    &--highlighted {
      background-color: #4f4f4f;
      color: $white;
    }
  }
}