@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-error {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  width: 100%;
  color: $white;
  font-size: 1.3125rem;
  letter-spacing: -0.01904761905em;
  will-change: transform;
  transition: transform 200ms ease-in-out;

  .c-page & {
    top: -123px;

    @mixin bp $tablet {
      top: -220px;
    }
  }

  &.s-dismissed {
    transform: translateY(-100%);
    pointer-events: none;
  }

  &__inner {
    position: relative;
    grid-column-end: span $columns;
    padding: 0.625rem 0;
    background-color: $red;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: -$pageGutter;
      left: -$pageGutter;
      z-index: -1;
      height: 100%;
      background-color: $red;

      .c-error--left & {
        right: -$gutter;
      }

      .c-error--right & {
        left: -$gutter;
      }
    }
  }

  &--3 &__inner {
    @mixin bp $tablet {
      grid-column-end: span 3;
    }
  }

  &__dismiss {
    .c-icon-cross {
      position: absolute;
      top: .5rem;
      right: .5rem;
      right: 0;
      width: 1.6875rem;
      height: 1.6875rem;
      border-color: $white;
      will-change: transform;
      transition: transform 200ms ease-out;

      &:before,
      &:after {
        width: 1rem;
        background-color: $white;
      }

    }

    &:hover {
      .c-icon-cross {
        transform: rotate(135deg);
      }
    }
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}