@import "../../../utils/css/variables.css";
@import "../../../utils/css/mixins.css";

.c-project-sub-line-item {
  align-items: center;
  margin-top: 1.1rem;
  margin-bottom: 1.4rem;

  @mixin bp $tablet {
    margin-top: .8rem;
    margin-bottom: .8rem;
  }

  &,
  a {
    color: $white;
  }

  &:first-child {
    @mixin bp $tablet {
      margin-top: 1.8rem;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    grid-column-start: 7;
    grid-column-end: 11;
    margin-left: calc(-2.8125rem - $gutter);
    font-size: 0.875rem;
    letter-spacing: -0.01642857143em;

    @mixin bp $tablet {
      grid-column-start: 4;
      grid-column-end: 9;
      margin-left: 0;
      font-size: 1.3125rem;
      letter-spacing: -0.01666666667em;
    }
  }

  &__title {
    margin: 0;
  }

  &__action {
    position: relative;
    top: 0.1rem;
    margin-left: 1em;

    @mixin bp $tablet {
      top: 0.02rem;
    }

    &--primary {
      background-color: $lightGrey;
      color: $black;
    }
  }

  &__toggle {
    grid-column-start: 11;
    grid-column-end: 13;
    margin: .5rem 0 0 auto;
    margin-top: 0;

    @mixin bp $tablet {
      grid-column-start: 9;
      margin-top: 0.8rem;
      margin-top: 0;
      margin-right: auto;
      margin-left: 0;
    }

    @mixin bp $laptop {
      grid-column-start: 10;
    }
  }
}