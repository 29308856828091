@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-search-form {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  line-height: 1;
  letter-spacing: -0.02857142857em;

  @mixin bp $tablet {
    font-size: 3.5rem;
  }

  &__input,
  &__submit {
    appearance: none;
    border: none;
    background-color: transparent;
  }

  &__input {
    flex: 1 1 auto;
    width: 100%;
    height: 3.05rem;
    padding-top: .5rem;
    padding-bottom: 0;
    padding-left: 0;
    font-size: 1em;
    /* background-color: blue; */
    letter-spacing: inherit;
    outline: none;
    color: $white;

    @mixin bp $tablet {
      padding-top: 1rem;
      height: 5.2rem;
    }

    &:focus::placeholder {
      color: $black;
    }

    &::placeholder {
      transition: color 200ms ease-out;
      color: $uiDarkGrey;
    }
  }

  &__submit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
  }

  .c-tag {
    position: relative;
    height: 1.6rem;
    margin-bottom: 0;
    padding-top: .55rem;
    font-size: .75rem;

    @mixin bp $tablet {
      top: 0.2rem;
      height: 2.5rem;
      padding: .9rem 1.1rem;
      font-size: 1rem;
      border-radius: 2rem;
    }
  }
}