@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-projects {
  position: relative;

  &__header {
    position: absolute;
    top: -3.1em;
    width: 100%;

    @mixin bp $tablet {
      top: -5.25em;
    }

    &__link-right {
      position: absolute;
      bottom: calc($gutter / 2);
      right: $pageGutter;
      display: block;

      @mixin bp $tablet {
        bottom: $gutter;
      }
    }
  }
  
  .c-search-form {
    width: calc(100% - $pageGutter * 2);
  }

  &__sort {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;

    &__inner {
      position: relative;
      width: 11.65rem;
    }

    label,
    select,
    option {
      font-size: 1rem;
      color: $lightGrey;
    }

    label {
      position: absolute;
      left: 0;
      pointer-events: none;
    }

    select {
      width: 100%;
      margin: 0;
      padding: 0 0 0 4.6625rem;
      appearance: none;
      background-color: transparent;
      border: none;
      outline: none;
    }

    .c-select__arrow {
      fill: $lightGrey;
    }

    &__icon {
      position: absolute;
      top: .1rem;
      right: 0;
      width: 0.9375rem;
      height: 0.6875rem;
      pointer-events: none;

      svg {
        width: 100%;
        height: 100%;
      }


    }
  }

  &__projects {
    margin-top: 4.3125rem;

    @mixin bp $tablet {
      margin-top: 2.55rem;
    }
  }

  .c-project-teaser {
    grid-column-end: span 12;

    @mixin bp $tablet {
      grid-column-end: span 3;
    }
  }
}