@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-partner {
  position: relative;
  z-index: 1;

  &.l-columns-container {
    @mixin bpMax $tablet {
      grid-gap: 13px;
    }
  }

  &__close {
    position: absolute;
    top: $gutter;
    right: $pageGutter;
    z-index: calc($zHeader + 1);
  }

  &__profile-picture__container,
  &__content {
    grid-column-end: span 12;

    @mixin bp $tablet {
      grid-column-end: span 6;
    }
  }

  &__profile-picture__container {
    /* @mixin bpMax $tablet { */
      position: relative;
      left: -$pageGutter;
      width: calc(100% + $pageGutter * 2);
    /* } */

    @mixin bp $tablet {
      width: calc(100% + $pageGutter);
    }
  }

  &__header,
  &__main {
    position: relative;
    border-bottom: 1px solid $uiDarkGrey;

    @mixin bpMax $tablet {
      left: -$pageGutter;
      width: calc(100% + $pageGutter * 2);
      padding-right: $pageGutter;
      padding-left: $pageGutter;
    }
  }

  &__header {
    padding-bottom: 4px;

    @mixin bp $tablet {
      padding-top: 1.25rem;
      padding-bottom: 14px;
    }
  }

  &__edit-toggle {
    position: absolute;
    right: $pageGutter;

    @mixin bp $tablet {
      position: relative;
      right: auto;
      margin-bottom: 3rem;
    }
  }

  &__headline {
    position: relative;
    display: block;
    margin: 0;
    font-size: 1.75rem;
    line-height: 1.0714285714;
    letter-spacing: -0.03214285714em;

    &__projects {
      position: relative;
      top: -0.9em;
      font-size: 0.875rem;
      line-height: 1;
      letter-spacing: -0.02142857143em;
    }
  }

  &__name {
    margin-top: 0;
    margin-bottom: 3.5625rem;

    @mixin bpMax $tablet {
      width: calc((100% - $gutter) / 2)
    }

    @mixin bp $tablet {
      margin-bottom: 3.3rem;
    }
  }

  &__main {
    margin-top: 2.3rem;
    padding-bottom: 4rem;

    @mixin bp $tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $gutter;
      margin-top: 1.25rem;
    }

    a {
      text-decoration: none;
    }
  }

  &__subheadline {
    margin-top: 1.3em;
    margin-bottom: .2em;
    color: $lightGrey;
  }

  &__text {
    margin-top: .2em;
  }

  &__list {
    list-style-type: none;
  }

  &__address &__text {
    white-space: pre-wrap;
  }

  &__projects {
    padding-top: 1rem;

    &__list {
      margin-top: 3.25rem;
      display: grid;
      grid-gap: $gutter;

      @mixin bp $tablet {
        margin-top: 2.65rem;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}