@import "../../../utils/css/variables.css";
@import "../../../utils/css/mixins.css";

.c-onboarding {
  &__top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: .8125rem $pageGutter;
    background-color: $bgColor;

    &__title,
    &__number {
      margin: 0;
      font-size: 2.1875rem;
      letter-spacing: -0.01514285714em;
      line-height: 1;
    }

    &__title {
      @mixin bp $tablet {
        font-size: 3.5rem;
      }
    }

    &__number {
      @mixin bp $tablet {
        margin-top: 0.15rem;
        font-size: 9.375rem;
      }
    }
  }

  .c-page {
    display: flex;
    flex-direction: column;
  }

  .c-page__bottom,
  .c-form {
    margin-top: auto;
    margin-bottom: 0;
  }

  .c-form {
    & > .field:first-child {
      @extend %grid;
      @extend %grid--outmost;

      & > fieldset:first-child {
        grid-column-end: span 12;

        @mixin bp $tablet {
          grid-column-end: span 5;
        }  
      }
    }

    & > div:last-child {
      background-color: $lightGrey;
    }

    &__bottom {
      align-items: center;
      left: 0;
      width: 100%;
      padding-right: $pageGutter;
      padding-left: $pageGutter;

      a {
        color: $black;
      }
    }

    &__headline {
      grid-column-end: span 12;

      @mixin bp $tablet {
        grid-column-end: span 4;
      }
    }
  }
  .form-group {
    /* display: grid; */
  }
}

.c-progress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 3px;
  background-color: $color;
}