@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-tag {
  display: inline-flex;
  height: 1.875rem;
  margin-right: .3125rem;
  margin-bottom: 0.375rem;
  padding: .65rem 1rem .5rem;
  background-color: $bgTag;
  border-radius: 0.9375rem;
  text-decoration: none;
  font-size: .75rem;
  line-height: 1;
  letter-spacing: -0.01666666667em;
  color: $colorTag;

  &--transparent {
    position: relative;
    padding: 0.3333em .5em 0.2em;
    border-radius: 2em;
    border: 1px solid $lightGrey;
    background-color: transparent;
    color: $lightGrey;
    font-size: 0.75rem;
    text-decoration: none;
  }

  &--small {
    height: 1.25rem;
  }

  &__text {
    margin: 0;
    white-space: nowrap;
  }

  &__cross {
    position: relative;
    width: 11px;
    height: 11px;
    margin-left: .75em;
    transform: rotate(45deg);

    @mixin bp $tablet {
      width: 15px;
      height: 15px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: currentColor;
    }

    &:before {
      width: 100%;
      height: 1px;
      top: calc((100% - 1px) / 2);
      left: calc((100% - 1em) / 2);
    }

    &:after {
      width: 1px;
      height: 100%;
      top: calc((100% - 1em) / 2);
      left: calc((100% - 1px) / 2);
    }
  }
}