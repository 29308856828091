@import "../../../../utils/css/variables.css";
@import "../../../../utils/css/mixins.css";

.c-welcome {
  &__text-container {
    flex: 0 1 auto;
    margin-top: auto;
    margin-bottom: .5rem;

    @mixin bp $tablet {
      margin-bottom: 2.125rem;
    }
  }

  &__text {
    grid-column-end: span 8;
    font-size: 1.75rem;
    line-height: 1.0714285714;
    letter-spacing: -0.03857142857em;

    @mixin bp $tablet {
      grid-column-end: span 5;
      font-size: 3.5rem;
      line-height: 1;
      letter-spacing: -0.015em;
    }
  }

  .c-onboarding & .c-form {
    margin-top: 0;
    margin-bottom: 0;
  }
}