@import "../../../utils/css/variables.css";
@import "../../../utils/css/mixins.css";

.c-icon-cross {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  /* margin-bottom: 10px; */
  background-color: $white;
  border-radius: 100%;

  @mixin bp $tablet {
    width: 50px;
    height: 50px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: $bgColor;

    @mixin bp $tablet {
      width: 25px;
    }
  }

  &:after {
    transform: rotate(90deg);
  }

  &--close,
  &--delete {
    transform: rotate(45deg);
  }

  &--close {
    background-color: $lightGrey;
  }

  &--delete {
    background-color: transparent;
    border: 1px solid $bgColor;

    &:before,
    &:after {
      height: 1px;

      @mixin bp $tablet {
        width: 20px;
      }
    }

    @mixin bp $tablet {
      width: 40px;
      height: 40px;
    }
  }
}