@import "./autosuggest.css";

.c-form__submit,
.c-input__input,
.c-input__button {
  appearance: none;
  border: none;
  outline: none;
}

.c-form {
  &__headline {
    max-width: calc((100vw - $gutter) / 2);
    margin-top: 0.6em;
    font-size: 1.3125rem;
    line-height: 1.1428571429;
    letter-spacing: -0.02857142857em;

    @mixin bp $tablet {
      margin-top: 0.55em;
      font-size: 1.75rem;
      letter-spacing: -0.03214285714em;
    }
  }

  &--completed &__bottom {
    padding-top: $gutter;
  }

  &--completed {
    .c-input__button {
      display: none;
    }
  }

  &__links {
    margin-top: 1.6rem;
  }

  &__main {
    position: relative;
    z-index: 1;
  }

  &__array-item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 3.5rem;
    }

    .c-input__button {
      flex: 0 0 auto;
      margin-left: .625em;
      margin-bottom: auto;
    }

    &--button-top-spacing {
      .c-input__button {
        margin-top: 1.5rem;
      }
    }
  }

  &__bottom {
    position: relative;
    display: flex;
    left: -$gutter;
    width: calc(100% + $gutter * 2);
    margin-top: 1.7rem;
    background-color: $lightGrey;

    @mixin bp $tablet {
      margin-top: 2.3rem;
    }
  }

  &__links + &__bottom {
    margin-top: 1rem;

    @mixin bp $tablet {
      margin-top: 1rem;
    }
  }

  &__submit {
    padding: .825em $gutter .6em;
    background-color: transparent;
    border-radius: 0;
    color: $buttonTextDark;
    text-decoration: none;
    font-size: 1.75rem;
    letter-spacing: -0.03857142857em;
    letter-spacing: -.06em;
    line-height: 1;
    cursor: pointer;

    @mixin bp $tablet {
      padding-top: 0.315em;
      padding-bottom: 0.13em;
      font-size: 3.5rem;
    }

    &:first-child {
      position: relative;
      left: -$gutter;
    }

    &:last-child:not(:first-child) {
      position: relative;
      right: -$gutter;
      margin-right: 0;
      margin-left: auto;
    }

    &--right {
      margin-right: 0;
      margin-left: auto;

      &,
      &:first-child {
        right: -$gutter;
        left: auto;
      }
    }
  }

  &__button {
    padding: 0.6em 1.333em 0.5em;
    border-radius: 2em;
    border: 1px solid currentColor;
    text-decoration: none;
    line-height: 1;
    font-size: 1.125rem;
    letter-spacing: -0.01944444444em;

    a&--secondary,
    &--secondary {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  a&__button--secondary {
    color: currentColor;
  }
}

.c-input {
  position: relative;
  width: 100%;

  &--hidden {
    display: none;
  }

  &:not(&--hidden) + & {
    margin-top: 1.3rem;

    @mixin bp $tablet {
      margin-top: 2.25rem;
    }
  }

  &__label,
  &__input {
    width: 100%;
  }

  &__label,
  &__message {
    font-size: .75rem;
    letter-spacing: -0.01666666667em;

    @mixin bp $tablet {
      font-size: 0.875rem;
      letter-spacing: -0.03571428571em;
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: .45em;

    @mixin bp $tablet {
      margin-bottom: .35em;
    }
  }

  &__description {
    &,
    a {
      color: $editInputGrey;
    }
  }

  &__input,
  &__file-container {
    border-radius: 2px;
  }

  input,
  input:-webkit-autofill,
  &__input,
  &__file-container__inner {
    transition: background-color 200ms ease-in-out;
    background-color: $darkGrey;

    &:hover,
    &:focus {
      background-color: $inputFocusBackground;
    }
  }

  &__input-container {
    position: relative;

    &--rating {
      display: flex;
    }

    svg {
      position: absolute;
      top: 0;
      right: 1.375rem;
      width: .75rem;
      height: 100%;
      pointer-events: none;
    }
  }

  &__file-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    overflow: hidden;

    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: calc((100% - 2px) / 2);
        left: calc((100% - $gutter) / 2);
        z-index: 1;
        background-color: $white;
        width: $gutter;
        height: 2px;

        .c-form--completed & {
          content: none;
        }
      }

      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &__input {
    color: $color;

    &,
    &__suggestion {
      height: 2.5rem;
      padding: 0.53125rem .65rem .2rem;
      font-size: 1rem;

      @mixin bp $tablet {
        height: 3.125rem;
        padding: .75rem .55rem .5rem;
        font-size: 1.25rem;
        letter-spacing: -0.01904761905em;
      }
    }
  
    &__suggestion {
      position: absolute;
      top: 0.45em;
      height: auto;
    }

    &--textarea {
      height: 20rem;
      font-family: $ffBody;
    }

    &--file {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  &__rating-number {
    display: flex;
    width: 3.8125rem;
    height: 3.8125rem;
    margin-right: .4375rem;
    padding-top: 0.3rem;
    text-decoration: none;
    border: 1px solid $white;
    border-radius: 50%;
    overfow: hidden;
    font-size: 1.75rem;
    line-height: 0;
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
    cursor: pointer;

    @mixin bp $tablet {
      width: 10.625rem;
      height: 10.625rem;
      margin-right: 1.25rem;
      font-size: 4.8125rem;
      padding-top: .8rem;
    }

    &:hover {
      background-color: $lightGrey;
      color: $black;
    }

    &.s-selected {
      background-color: $white;
      color: $black;
    }

    span {
      display: block;
      margin: auto;
    }
  }

  &__message {
    position: absolute;
    top: 4.1rem;
  }

  &__button {
   padding: 0;
   min-height: 0;
   background-color: transparent;
   cursor: pointer;
  }
}


.c-form-group {
  &--inline {
    display: grid;
    grid-gap: 1px;

    & > fieldset {
      display: contents;
    }

    .c-input + .c-input {
      margin-top: 0;
    }
  }

  &--location {
    grid-template-columns: 1fr 1fr;

    .c-input + .c-input {
      @mixin bp $tablet {
        margin: 0;
      }
    }
  }
}
