@define-mixin bp $width {
  @media screen and (width >= $width) {
    @mixin-content;
  }
}

@define-mixin bpMax $width {
  @media screen and (width < $width) {
    @mixin-content;
  }
}