@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-project-line-item {
  position: relative;
  border-bottom: 1px solid $uiDarkGrey;
  overflow: hidden;

  &,
  a {
    color: $lightGrey;
  }

  &--collapsed {
    height: 3.45rem;

    @mixin bp $tablet {
      height: 4.55rem;
    }
  }

  &__title,
  &__number {
    line-height: 1;
  }

  &__title {
    grid-column-start: 1;
    grid-column-end: 5;
    margin-top: .8125rem;
    margin-bottom: 1.25rem;
    font-size: 1.3125rem;
    letter-spacing: -0.02857142857em;
    text-decoration: none;

    @mixin bp $tablet {
      grid-column-end: 3;
      margin-top: 1.2rem;
      font-size: 1.75rem;
      letter-spacing: -0.01678571429em;
    }
  }

  &__toggle {
    grid-column-start: 5;
    grid-column-end: 7;
    margin-top: .875rem;
    margin-bottom: 0.875rem;

    @mixin bpMax $tablet {
      margin-right: 0;
      margin-left: auto;
    }

    @mixin bp $tablet {
      grid-column-start: 4;
      grid-column-end: 6;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }

  &__actions {
    grid-column-start: 7;
    grid-column-end: 11;

    &--top {
      margin-top: 1.1rem;
      margin-bottom: .875rem;

      @mixin bp $tablet {
        grid-column-start: 9;
        grid-column-end: 12;
      }

      @mixin bp $laptop {
        grid-column-start: 10;
      }

      @mixin bp $tablet {
        margin-top: 1.66rem;
      }
    }

    &--bottom {
      margin-bottom: 2.2rem;
      margin-left: calc(-2.8125rem - $gutter);

      @mixin bp $tablet {
        grid-column-start: 4;
        grid-column-end: 8;
        margin-top: .5rem;
        margin-bottom: 2.4rem;
        margin-left: 0;
      }
    }
  }

  &__action {
    height: 1.5625rem;
    margin-top: 0;
    padding: 0.6em 1.1em 0.333em;
    border-radius: 2em;
    border: 1px solid $lightGrey;
    color: $lightGrey;
    font-size: 0.75rem;
    text-decoration: none;

    @mixin bp $tablet {
      height: 2rem;
      padding: 0.6em 1.85em 0.5em;
      font-size: .875rem;
    }

    a&--primary {
      background-color: $lightGrey;
      color: $black;
    }
  }

  &__action + &__action {
    margin-left: calc($gutter / 2);

    @mixin bp $tablet {
      margin-left: $gutter;
    }
  }

  &__checkmark {
    position: absolute;
    top: .875rem;
    right: 3rem;
    grid-column-start: 12;
    width: 1.875rem;
    height: 1.4375rem;

    @mixin bp $tablet {
      top: 1.0625rem;
      right: 4rem;
      width: 2.75rem;
      height: 2.0625rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__number {
    grid-column-start: 12;
    margin-top: .75rem;
    margin-bottom: 0;
    text-align: right;
    font-size: 2.1875rem;

    @mixin bp $tablet {
      margin-top: 1.0625rem;
      font-size: 2.875rem;
    }
  }

  &__line {
    position: relative;
    left: -$pageGutter;
    grid-column-start: 1;
    grid-column-end: 13;
    width: calc(100% + $pageGutter * 2);
    height: 1px;
    background-color: $uiDarkGrey;

    @mixin bp $tablet {
      left: 0;
      grid-column-start: 4;
      grid-column-end: 13;
      width: cacl(100% + $pageGutter);
    }
  }

  &__subline-line-items {
    margin-top: .6rem;
    margin-bottom: 1.875rem;

    @mixin bp $tablet {
      margin-top: 1.15rem;
      margin-bottom: 1.6666rem;
    }
  }
}