@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-partner-teaser {
  display: flex;
  flex-direction: column;
  grid-column-end: span 12;
  margin-bottom: 3.6875rem;
  padding-bottom: .9rem;
  border-bottom: 1px solid $uiDarkGrey;

  @mixin bp $tablet {
    grid-column-end: span 3;
  }

  &__profile-image-container {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 67.64705882%;
    background-color: $buttonTextDark;
    border-radius: 2px;
    overflow: hidden;
  }

  &__profile-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }

  &__name,
  &__locations {
    font-size: 1.25rem;
    line-height: 1.15;
    letter-spacing: -0.035em;
  }

  &__name {
    display: inline-block;
    margin-top: 1.4rem;
    margin-bottom: 0;
    text-decoration: none;

    &__projects {
      position: relative;
      top: -0.3em;
      font-size: 0.875rem;
      line-height: 1;
      letter-spacing: -0.02142857143em;
    }
  }

  &__locations {
    margin-bottom: 2rem;
    color: $lightGrey;
  }

  &__location {
    margin-top: 0;
    margin-bottom: 0;

    &:last-of-type {
      display: inline-block;
    }

    &__dots {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 1em;
      height: 1em;
      top: -0.2em;
      margin-left: 0.3em;  
      background-color: $bgTag;
      border-radius: 50%;
      font-weight: bold;
      letter-spacing: -.5em;

      span {
        width: 3px;
        height: 3px;
        background-color: $colorTag;
        border-radius: 50%;  
      }

      span + span {
        margin-left: 1px;
      }
    }
  }

  &__services {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 0;
  }
}