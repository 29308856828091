@import "../../../utils/css/variables.css";
@import "../../../utils/css/mixins.css";

.c-create-project {
  display: flex;
  flex-direction: column;

  &__title {
    grid-column-end: span 12;
    font-size: 1.3125rem;
    line-height: 1.0952380952;
    letter-spacing: -0.02857142857em;

    @mixin bp $tablet {
      grid-column-end: span 3;
      font-size: 1.75rem;
    }
  }

  &__main {
    margin-top: auto;
    margin-bottom: 0;
  }

  &__form {
    &__inner {
      grid-column-end: span 12;

      @mixin bp $tablet {
        grid-column-end: span 5;
      }
    }
  }

  .c-form {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    & > div:not(.c-form__bottom) {
      grid-column-end: span 12;

      @mixin bp $tablet {
        grid-column-end: span 5;
      }
    }

    &__bottom {
      grid-column-start: 1;
      grid-column-end: span 12;
      padding-right: $pageGutter;
      padding-left: $pageGutter;
      color: $bgTag;
    }
  }
}