@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-header {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  transition: color 300ms ease-in-out;

  .c-app--is-editing & {
    @mixin bpMax $tablet {
      z-index: 1;
    }
  }

  &--open {
    color: $black;
  }

  @mixin bp $tablet {
    z-index: 1;
    color: $white;
  }

  a {
    color: currentColor;
  }

  path {
    fill: $color;
  }

  &__background {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    will-change: transform;
    transition: transform 300ms ease-in-out;

    .c-header--background & {
      transform: translateY(100%);
    }
  }

  &__inner {
    padding-bottom: 0.6875rem;
  }

  &__logo {
    path&__network {
      fill: currentColor;
    }
  }

  &__logo-container,
  &__nav,
  .c-hamburger,
  &__notifications {
    padding-top: 13px;

    @mixin bp $tablet {
      padding-top: $gutter;
    }
  }

  &__logo-container {
    position: relative;
    z-index: 1;

    @mixin bp $tablet {
      grid-column-end: span 3;
    }
  }

  &__nav__link,
  &__user {
    @mixin bp $tablet {
      font-size: 1.3125rem;
      letter-spacing: -0.02857142857em;
    }
  }

  &__nav,
  &__user {
    @mixin bpMax $tablet {
      will-change: transform;
      transition: transform 300ms ease-in-out;
    }

    .c-header--open & {
      @mixin bpMax $tablet {
        transform: translateX(-100vw);
      }
    }
  }

  &__nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    @mixin bpMax $tablet {
      top: 0;
      bottom: 0;
      left: 100vw;
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      padding: $pageGutter;
      font-size: 3.125rem;
      line-height: 1.3;
      letter-spacing: -0.0286em;
      background-color: $lightGrey;
    }

    @mixin bp $tablet {
      position: relative;
      top: -0.1rem;
      display: block;
      grid-column-end: span 6;
    }

    &--hidden {
      @mixin bp $tablet {
        display: none;
      }
    }

    a&__link {
      position: relative;
      display: inline-block;
      text-decoration: none;
      margin-right: auto;
      color: $black;

      @mixin bp $tablet {
        margin-right: $gutter;
        color: $white;
      }

      &.s-active {  
        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: .5625rem;
          left: 0;
          height: 1px;
          background-color: currentColor;

          @mixin bp $tablet {
            bottom: 2px;
          }
        }
      }
    }
  }

  &__user {
    position: absolute;
    top: 123px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @mixin bpMax $tablet {
      left: 100vw;
      color: $black;
      padding: $pageGutter;
    }

    @mixin bp $tablet {
      position: relative;
      top: 18px;
      display: block;
      height: 1.4em;
      grid-column-start: 10;
      grid-column-end: span 2;
      overflow: hidden;
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 1px;
      background-color: currentColor;

      @mixin bpMax $tablet {
        content: "";
      }
    }

    &:hover {
      .c-header__username {
        @mixin bp $tablet {
          transform: translateY(-1.5em);
        }
      }

      .c-header__signout {
        @mixin bp $tablet {
          transform: translateY(0);
        }
      }
    }
  }


  &__username,
  &__signout {
    margin: 0;

    @mixin bp $tablet {
      position: absolute;
      display: block;
      will-change: transform;
      transition: transform 200ms ease-out;
    }
  }

  &__username {
    @mixin bpMax $tablet {
      font-size: 1.3125rem;
      letter-spacing: -0.02857142857em;
    }
  }

  &__signout {
    text-decoration: none;

    @mixin bpMax $tablet {
      border-radius: 1.3em;
      padding: .6em 1em 0;
      border: 1px solid currentColor;
      font-size: .75rem;
      line-height: 1;
      letter-spacing: -0.01666666667em;
    }

    @mixin bp $tablet {
      transform: translateY(1.5em);
    }
  }

  &__notifications {
    position: absolute;
    right: $pageGutter;
    text-align: right;


    @mixin bp $tablet {
      position: relative;
      right: auto;
      grid-column-start: 12;
      grid-column-end: 13;
    }

    &__counter-container {
      position: relative;
      top: -.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.6875rem;
      height: 1.6875rem;
      margin-right: 0;
      margin-left: auto;
      border-radius: 50%;
      border: 1px solid currentColor;
      text-decoration: none;

      @mixin bp $tablet {
        top: -.3rem
      }
    }

    &__counter {
      position: relative;
      top: 0.1rem;
      margin: 0;
      font-size: 1rem;

      @mixin bp $tablet {
        top: 0.05rem;
        font-size: 1.125rem;
      }
    }
  }
}

.c-hamburger {
  position: absolute;
  right: 56px;
  top: 1px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 44px;
  height: 44px;

  @mixin bp $tablet {
    display: none;
  }

  &__line {
    position: absolute;
    top: 50%;
    width: 25px;
    height: 2px;
    background-color: currentColor;
    transition: transform 300ms ease-in-out;

    &:first-child {
      transform: translateY(-3px) rotate(0deg);

      .c-header--open & {
        transform: translateY(0px) rotate(-45deg)
      }
    }

    &:last-child {
      transform: translateY(3px) rotate(0deg);
      
      .c-header--open & {
        transform: translateY(0px) rotate(45deg);
      }
    }
  }
}