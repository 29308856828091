@import "../../../../utils/css/variables.css";
@import "../../../../utils/css/mixins.css";

.c-edit {
  position: fixed;
  top: 0;
  bottom: 0;
  left: calc(100vw - $pageGutter);
  z-index: 10000;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  &__inner {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 13;
    width: calc(100% + $gutter * 2);
    background-color: $lightGrey;
    padding: $gutter;
    
    @mixin bp $tablet {
      width: calc(100% + $gutter * 2);
      grid-column-end: 5;
    }

    .c-link {
      flex: 1 0 auto;
      display: block;

      & + .c-form {
       .c-input {
          display: none;
        } 
      }
    }

    .c-link + .c-link {
      margin-top: 1px;
    }
  }

  & .c-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__bottom {
      align-items: flex-end;
      margin-top: auto;
      margin-bottom: 0;
      left: 0;
      width: 100%;
    }

    &__button {
      margin-bottom: 0;
    }

    &__submit {
      bottom: -13px;
      padding-bottom: 0;
      line-height: 1;
    }
  }

  .c-input__input,
  .c-input__file-container__inner {
    background-color: $editInputGrey;

    &:hover,
    &:focus {
      background-color: $colorTag;
    }
  }

  .c-form__button,
  .c-input__label,
  .c-input__input {
    color: $black;
  }
}

button {
  min-height: 44px;
}