@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-project-teaser {
  display: block;

  &__line {
    position: relative;
    display: block;
    padding: .55rem .875rem .475rem;
    background-color: $bgTag;
    border-radius: 2px;
    overflow: hidden;
    text-decoration: none;

    @mixin bp $tablet { 
      padding: .55rem .875rem .5125rem;
    }

    & + & {
      margin-top: 1px;
    }

    &--main {
      display: flex;
      flex-direction: column;
      height: 10rem;
    }
  }

  &__headline,
  &__categories,
  &__next-step__text {
    margin: 0;
    font-size: 1.25rem;
    letter-spacing: -0.035em;
    line-height: 1.15;
  }

  &__headline {
    margin-top: 0.1rem;
    padding-right: 2rem;
  }

  &__categories,
  &__step {
    color: $editInputGrey;
  }

  &__project-manager {
    position: absolute;
    top: .5625rem;
    right: .5625rem;
    width: 2rem;
    height: 2rem;
    margin: 0;
    padding-top: 1.1rem;
    background-color: $darkGrey;
    border-radius: 50%;
    text-transform: uppercase;
    color: $lightGrey;
    font-size: 1.125rem;
    line-height: 0;
    text-align: center;
  }

  &__step {
    display: flex;
    font-size: 1.125rem;
    letter-spacing: -0.02777777778em;

    &--next {
      color: $white;
    }

    &__title {
      margin: 0 0 0.75rem;
    }

    &__checkmark {
      width: 1.4375rem;
      height: 1.125rem;
      margin: .5rem .8rem 0 auto;

      .c-checkmark__stroke {
        stroke: currentColor;
        stroke-width: 4;
      }
    }

    &__notification {
      width: 1.25rem;
      height: 1.25rem;
      margin: .2rem .8rem 0 auto;
      border-radius: 50%;
      background-color: $red;
    }

    &__number {
      width: .75rem;
      margin: .15rem 0 0 0;
      font-size: 1.375rem;
    }

    &__title + &__number {
      margin-left: auto;
    }


    .c-icon-arrow {
      position: absolute;
      top: .25rem;
      right: .6rem;
      width: 1.25rem;

      svg {
        width: 100%;
        height: 100%;
      }
      
      g {
        stroke: currentColor;
      }
    }

    p::first-letter {
      text-transform: capitalize;
    }
  }
}