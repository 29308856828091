@import "../../utils/css/variables.css";
@import "../../utils/css/mixins.css";

.c-link {
  grid-column-start: 1;
  grid-column-end: span 12;
  text-decoration: none;
  background-color: $bgTag;
  border-radius: 2px;
  overflow: hidden;

  @mixin bp $tablet {
    grid-column-end: span 5;
  }

  &__visual-container {
    position: relative;
    height: 0;
    padding-bottom: 64.13793103%;
  }

  &__visual {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
  }

  &--no-visual {
    min-height: 5.625rem;

    @mixin bp $tablet {
      min-height: 13.125rem;
    }

    .c-icon-arrow--up-right {
      width: 1.75rem;
      height: 1.75rem;

      @mixin bp $tablet {
        width: 2.5rem;
        height: 2.5rem;
      }

      svg {
        width: 100%;
        height: 100%;
      }

      g {
        @mixin bp $tablet {
          stroke-width: 1.5;
        }
      }
    }
  }

  &--visual &__text-container {
    padding: .875rem .875rem 2.15rem;

    @mixin bp $tablet { 
      padding: .825rem .875rem 3rem;
    }
  }

  &--no-visual &__text-container {
    padding: .875rem .875rem .95rem;

    @mixin bp $tablet { 
      padding: 1.4rem 1.25rem 1.65rem;
    }
  }

  &__text-container {
    position: relative;
    font-size: 1.125rem;
    line-height: 1.1111111111;
    letter-spacing: -0.02777777778em;

    @mixin bp $tablet {
      font-size: 1.3125rem;
      line-height: 1.0952380952;
      letter-spacing: -0.02761904762em;
    }
  }

  &__link-title,
  &__link-subtitle {
    margin: 0;
  }

  &__link-subtitle {
    max-width: 13em;
    margin-top: 0.1rem;
    color: $editInputGrey;
  }

  .c-icon-arrow {
    position: absolute;
    top: .75rem;
    right: .5rem;

    @mixin bp $tablet {
      top: 1rem;
      right: .65rem;
    }
  }
}